#receipt-header-container {
    padding: 15px 15px;
    width: 100%;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    height: 71px;
    background-color: White;
}

#receipt-header-container .header-title {
    font-size: 24px;
    color: #202020;
    font-weight: 700;
}