#finance-breadcrumbs {

    .breadcrumbs ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .breadcrumbs ul li {
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        color: #a0a0a0;
        padding-right: 8px;
    }

    .breadcrumbs ul li a {
        color: #a0a0a0;
        text-decoration: none;
    }

    .breadcrumbs ul li a:hover {
        color: #24c4cc;
        text-decoration: none;
    }

    .breadcrumbs ul li.b-nolink {
        font-weight: bold;
    }

    .m-ap-top .breadcrumbs {
        width: calc(100% - 160px);
    }
}